// MenuIcon.js
import React from 'react';
import { FaBars } from 'react-icons/fa';

const Menu = ({ onClick }) => {
  return (
    <div className="menu-icon" onClick={onClick}>
      <FaBars style={{ fontSize: '24px' }}/>
    </div>
  );
};

export default Menu;
