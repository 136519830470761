import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../animations/cruzVermelha.json';

const CruzVermelha = ({ isMobile }) => {
  const containerRef = useRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  const mobileStyles = isMobile
    ? {
        transform: 'translateY(-50%)',
        height: '56px', 
        position: 'relative',
        top: '-21px',
        left: '20%'
      }
    : {
        transform: 'translateY(-50%)',
        height: '100px',
      };

  return <div ref={containerRef} style={mobileStyles} />;
};

export default CruzVermelha;

