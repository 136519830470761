import React, { useState, useEffect, useRef } from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import "./App.css";
import SosComponent from "./componentes/SosComponent";
import Menu from "./componentes/Menu";
import ColorLegend from "./componentes/ColorLegend";
import UsuarioService from "./services/UsuarioService";
import logo from "./images/logoPrefeitura.png";
import logoNeat from "./images/logoNeat.png";
import CruzVermelha from "./componentes/cruzVermelha";
import sirene from "./audio/sirene.mp3";
import Error from "./componentes/Error";

const usuarioService = new UsuarioService();

function App() {
  const [currentTicket, setCurrentTicket] = useState({});
  const [previousTickets, setPreviousTickets] = useState([]);
  const [isSirenPlaying, setIsSirenPlaying] = useState(false);
  const [lastTicketDtHr, setLastTicketDtHr] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState({ status: null, message: "" });

  const audioRef = useRef(new Audio(sirene));
  audioRef.current.loop = false;

  const fetchData = async () => {
    try {
      const response = await usuarioService.get(
        `/api/ocorrencias/municipio/CAMPINA GRANDE/ultimas10/`
      );
      const newTicketData = response.data.map((ocorrencia) => ({
        OcorrenciaID: ocorrencia.OcorrenciaID,
        Bairro: ocorrencia.Bairro,
        ReferenciaDS: ocorrencia.ReferenciaDS,
        Municipio: ocorrencia.Municipio,
        MotivoDS: ocorrencia.MotivoDS,
        TipoLogradouro: ocorrencia.TipoLogradouro,
        Apelido: ocorrencia.OcorrenciaApelido,
        Logradouro: ocorrencia.Logradouro,
        OcorrenciaFinalDT: ocorrencia.OcorrenciaFinalDT,
        OperadorID: ocorrencia.OperadorID,
        OperadorNM: ocorrencia.OperadorNM,
        RISCODS: ocorrencia.RISCODS,
        DtHr: formatarData(ocorrencia.DtHr),
        DtHrCriacao: formatarData(ocorrencia.created_at),
        OperadorNM: ocorrencia.OperadorNM
      }));

      if (!areArraysEqual(newTicketData, previousTickets)) {
        setCurrentTicket(newTicketData[0]);
        setPreviousTickets(newTicketData.slice(1));

        if (
          lastTicketDtHr !== null &&
          lastTicketDtHr !== newTicketData[0].DtHr
        ) {
          await playSiren();
        }
        setLastTicketDtHr(newTicketData[0].DtHr);
      } else {
        console.error("wwwwwwww:", response);
      }
    } catch (error) {
      const errorStatus = error.response ? error.response.status : "Erro";
      let errorBack = error.response
        ? error.response.data.detail
        : "Erro desconhecido";
      if (errorBack === undefined) {
        errorBack = error.message;
      }

      setError({ status: errorStatus, message: errorBack });
    }
  };

  const loadInitialData = async () => {
    fetchData();
  };

  const sirenButtonRef = useRef(null);

  const playSiren = async () => {
    console.log("Reproduzindo o áudio !!!!");
    simulateUserInteraction();

    // Inicia a reprodução apenas se o áudio estiver totalmente carregado
    if (audioRef.current.readyState >= 3) {
      console.log("audioRef ", audioRef.current);
      try {
        await audioRef.current.play();
        setIsSirenPlaying(true);
      } catch (error) {
        console.error("Erro ao reproduzir áudio:", error.message);
      }
    } else {
      console.log("Áudio ainda não totalmente carregado. Aguarde.");
    }
  };

  const simulateUserInteraction = () => {
    const sirenButton = sirenButtonRef.current;

    if (sirenButton) {
      sirenButton.click();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    loadInitialData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
      //audioRef.current.pause();
      //audioRef.current.currentTime = 13000;
    };
  }, [previousTickets]);

  useEffect(() => {
    const handleEnded = () => {
      setIsSirenPlaying(false);
    };

    audioRef.current.addEventListener("ended", handleEnded);

    return () => {
      audioRef.current.removeEventListener("ended", handleEnded);
    };
  }, []);

  useEffect(() => {
    // Função para calcular o próximo horário de 2h da manhã
    const getNext2AM = () => {
      const now = new Date();
      const next2AM = new Date(now);
      next2AM.setHours(2, 0, 0, 0);
      if (now.getHours() >= 2) {
        next2AM.setDate(next2AM.getDate() + 1);
      }
      return next2AM;
    };

    // Calcular o tempo restante até 2h da manhã
    const calculateTimeUntilNext2AM = () => {
      const now = new Date();
      const next2AM = getNext2AM();
      const timeUntilNext2AM = next2AM - now;
      return timeUntilNext2AM;
    };

    // Agendar o reload para 2h da manhã
    const scheduleReloadAt2AM = () => {
      const timeUntilNext2AM = calculateTimeUntilNext2AM();
      setTimeout(() => {
        console.log("Reload da página ocorreu às 2h da manhã.");
        window.location.reload(); // Recarregar a página
      }, timeUntilNext2AM);
    };

    // Chamar a função para agendar o reload
    scheduleReloadAt2AM();

    // Atualizar o agendamento do reload todos os dias à meia-noite
    const interval = setInterval(() => {
      scheduleReloadAt2AM();
    }, 24 * 60 * 60 * 1000); // 24 horas em milissegundos

    // Limpar o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []);

  const formatarData = (data) => {
    const dataObj = new Date(data);
    const dia = padLeft(dataObj.getDate(), 2);
    const mes = padLeft(dataObj.getMonth() + 1, 2);
    const ano = padLeft(dataObj.getFullYear() % 100, 2);
    const horas = padLeft(dataObj.getHours(), 2);
    const minutos = padLeft(dataObj.getMinutes(), 2);

    return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
  };

  const padLeft = (value, length) => {
    return String(value).padStart(length, "0");
  };

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (!isObjectEqual(array1[i], array2[i])) {
        return false;
      }
    }

    return true;
  };

  const isObjectEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className={`container ${isMobile ? "mobile" : ""}`}>
      {error.status ? (
        <Error status={error.status} message={error.message} />
      ) : (
        <div
          style={{
            display: !isMobile ? "flex" : "block",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div className="sos">
            <SosComponent color={currentTicket.RISCODS} isMobile={isMobile} />
          </div>
          <div className={`chamado-atual ${isMobile ? "mobile" : ""}`}>
            <div>
              {isMobile && <Menu />}
              <CruzVermelha isMobile={isMobile} />
            </div>
            <p style={{ fontSize: "2.5em" }}>
              <span className="poppins bg-text">{currentTicket.DtHr}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Inserido no sistema em: </strong>{" "}
              <span className="poppins ">{currentTicket.DtHrCriacao}</span>
            </p>

            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Ocorrencia ID: </strong>{" "}
              <span className="poppins "> {currentTicket.OcorrenciaID}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Tipo: </strong>{" "}
              <span className="poppins ">{currentTicket.MotivoDS}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Apelido: </strong>{" "}
              <span className="poppins ">{currentTicket.Apelido}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Logradouro: </strong>{" "}
              <span className="poppins">
                {currentTicket.TipoLogradouro} {currentTicket.Logradouro}
              </span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Bairro: </strong>{" "}
              <span className="poppins"> {currentTicket.Bairro}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <strong className="poppins-gold bg-text">Operador: </strong>{" "}
              <span className="poppins"> {currentTicket.OperadorNM}</span>
            </p>
            <p style={{ fontSize: "2.5em" }}>
              <span className="poppins">{currentTicket.ReferenciaDS}</span>
            </p>
            <div
              className="siren-button"
              ref={sirenButtonRef}
              onClick={playSiren}
            >
              {isSirenPlaying ? (
                <FaVolumeMute color="transparent" />
              ) : (
                <FaVolumeUp color="transparent" />
              )}
            </div>
            <ColorLegend />
            <br />
            <br />
            <div className="logo-div">
              <img src={logo} alt="Logo Prefeitura" className="logo-image" />
              <img src={logoNeat} alt="Logo Neat" className="logo-neat" />
            </div>
          </div>
          <div className="ultimos-chamados">
            <div className="previous-tickets-list-title poppins">
              CHAMADOS ANTERIORES
            </div>
            <ul
              className="previous-tickets-list"
              style={{
                display: isMobile ? "flex" : "block",
                flexDirection: "row",
                listStyle: "none",
                padding: 0,
                overflowX: "auto",
                marginBottom: "500px",
              }}
            >
              {previousTickets.slice(-20).map((ticket, index) => (
                <li
                  key={index}
                  className="number-changed number-changed2"
                  style={{
                    backgroundColor: getPastelColorFromRealColor(ticket.RISCODS)
                      .backgroundColor,
                    color: getPastelColorFromRealColor(ticket.RISCODS)
                      .textColor,
                    marginLeft: isMobile ? "10px" : "300px", // Adiciona margem apenas se for mobile
                  }}
                >
                  <div
                    className="balao-conversa"
                    style={{
                      backgroundColor: getPastelColorFromRealColor(
                        ticket.RISCODS
                      ).backgroundColor,
                      borderColor: getPastelColorFromRealColor(ticket.RISCODS)
                        .backgroundColor,
                    }}
                  >
                    <div className="balao-content" style={{ lineHeight: "1" }}>
                      <p>
                        <strong>{ticket.DtHr}</strong>
                      </p>
                      <p>
                        <strong>Inserido no sistema em: </strong>{" "}
                        <strong>{ticket.DtHrCriacao}</strong>
                      </p>
                      <p>
                        <strong>Ocorrencia ID: </strong>{" "}
                        <span> {ticket.OcorrenciaID}</span>
                      </p>
                      <p>
                        <strong>Tipo: </strong>{" "}
                        <span className="poppins">{ticket.MotivoDS}</span>
                      </p>
                      <p>
                        <strong>Apelido: </strong>{" "}
                        <span className="poppins">{ticket.Apelido}</span>
                      </p>
                      <p>
                        <strong>Logradouro: </strong>{" "}
                        <span className="poppins">
                          {ticket.TipoLogradouro} {ticket.Logradouro}
                        </span>
                      </p>
                      <strong>Bairro: </strong>{" "}
                      <span className="poppins">{ticket.Bairro}</span>
                      <p style={{ display: isMobile ? "none" : "block" }}>
                        <strong>{ticket.ReferenciaDS}</strong>
                      </p>
                      <strong>Operador: </strong>{" "}
                      <span className="poppins">{ticket.OperadorNM}</span>
                      <p style={{ display: isMobile ? "none" : "block" }}>
                        <strong>{ticket.ReferenciaDS}</strong>
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

function getPastelColorFromRealColor(risco) {
  const cor = extrairCor(risco);

  switch (cor) {
    case "RISCO INDEFINIDO":
      return {
        backgroundColor: "#24c0eb",
        textColor: "black",
        fontSize: "1.5em",
      };
    case "** NÃO INFORMADO **":
      return {
        backgroundColor: " #e88a25",
        textColor: "black",
        fontSize: "1.5em",
      };
    case "RISCO ALTO":
      return {
        backgroundColor: "#cc2a41",
        textColor: "white",
        fontSize: "2em",
      };
    case "RISCO MÉDIO":
      return { backgroundColor: "#f9e14b", textColor: "black" };
    case "PRETO":
      return { backgroundColor: "black", textColor: "white" };
    case "RISCO BAIXO":
      return { backgroundColor: "#7abf66", textColor: "black" };
    case "** NÃO REGULADO **":
      return { backgroundColor: "#B9B9B9", textColor: "black" };
    default:
      return { backgroundColor: "white", textColor: "black" };
  }
}

function extrairCor(risco) {
  const match = risco.match(/\(([^)]+)\)/);
  return match ? match[1].toUpperCase() : risco.toUpperCase();
}

export default App;
