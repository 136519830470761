import React from 'react';

const ColorLegend = () => {
  const colorLegend = [
    { color: '#f9e14b', info: 'Risco Médio' },
    { color: '#cc2a41', info: 'Risco Alto' },
    { color: '#24c0eb', info: 'Risco Indefinido' },
    { color: 'black', info: 'Preto' },
    { color: '#7abf66', info: 'Risco Baixo' },
    { color: 'gray', info: ' Não Regulado' },
    { color: '#e88a25', info: 'Não Informado' },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {colorLegend.map((item, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: item.color, marginRight: '5px' , borderRadius: '50%'}} />
          <p style={{ margin: '0', fontSize: '14px' }}>{item.info}</p>
        </div>
      ))}
    </div>
  );
};

export default ColorLegend;
