import axios from "axios";

export default class HttpService {
  constructor() {
    try {
      this.axios = axios.create({
        baseURL: this.getBaseUrl()
      });

      this.axios.interceptors.request.use((config) => {
        config.headers.Authorization = 'Token 7db7bc17d3bd63c68f16e3100cc813be3286b0bc' ;
        return config;
      });
    } catch (error) {
      console.log(error);
    }
  }

  getBaseUrl() {
    const currentHostname = window.location.hostname;
    const isLocalhost = currentHostname === 'localhost' && (window.location.port === '3000' || window.location.port === '3001');
    if (isLocalhost) {
      return 'http://10.0.0.160/api';
    } else {
      const isUsuarioInterno = currentHostname.startsWith('10.0.0');
      return isUsuarioInterno ? 'http://10.0.0.160:3001' : 'https://apisamu.sttp.campinagrande.br';
    }
  }

  async post(url, data) {
    try {
      const response = await this.axios.post(url, data);
      return response;
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  get(url) {
    return this.axios.get(url);
  }

  async patch(url, data) {
    try {
      const response = await this.axios.patch(url, data);
      return response;
    } catch (error) {
      console.log(error);
      return { error };
    }
  }
}
