// SosComponent.js
import React from 'react';
import SosRed from './SosRed';
import SosYellow from './SosYellow';
import SosOrange from './SosOrange';
import SosGreen from './SosGreen';
import SosCinza from './SosCinza';
import SosBlue from './SosBlue';
import SosBlack from './SosBlack';

const SosComponent = ({ color, isMobile  }) => {
  switch (color) {
    case 'VERMELHO(RISCO ALTO)':
      return <SosRed isMobile={isMobile} />;
    case "AMARELO(RISCO MÉDIO)":
      return <SosYellow isMobile={isMobile}/>;
    case 'AZUL(RISCO INDEFINIDO)':
      return <SosBlue isMobile={isMobile}/>;
    case 'PRETO':
      return <SosBlack isMobile={isMobile}/>;
    case 'VERDE(RISCO BAIXO)':
      return <SosGreen isMobile={isMobile}/>;
    case '** NÃO REGULADO **':
      return <SosCinza isMobile={isMobile}/>;
    case '** NÃO INFORMADO **':
      return <SosOrange isMobile={isMobile}/>;
    default:
      return null;
  }

  
};



export default SosComponent;
