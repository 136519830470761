import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../animations/circuloLaranja.json'

const SosOrange = ({ isMobile }) => {
  const containerRef = useRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true, // Defina loop como true para que a animação seja repetida
      autoplay: true,
      animationData: animationData,
    });

    let startTime = Date.now();

    const updateAnimation = () => {
      const elapsed = Date.now() - startTime;

      if (elapsed >= 13000) {
        anim.stop(); // Pare a animação após 15 segundos
      } else {
        requestAnimationFrame(updateAnimation);
      }
    };

    updateAnimation();

    // Limpe o temporizador ao desmontar o componente
    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: isMobile ? 'fixed' : 'fixed', 
        left: isMobile ? '35%' : 0,
        top: isMobile ? '-90px' : '50%',
        transform: isMobile ? 'translate(-50%, 0) rotate(90deg)' : 'translateY(-50%)',
        height: isMobile ? '300px' : '760px', 
      }}
    />
  );
};

export default SosOrange;
