import React from 'react';
import imgError from '../images/error.png'

const Error = ({ status, message }) => (
  <div style={{ textAlign: 'center' }}>
    <img src={imgError} 
        alt="Imagem de Erro" 
        style={{ maxWidth: '80%', maxHeight: 'auto', borderRadius: '25px' }} 
    />
    <h2>Status: {status}</h2>
    <p>Mensagem: {message}</p>
  </div>
);

export default Error;
