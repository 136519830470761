import HttpService from "./HttpService";

export default class UsuarioService extends HttpService {
  async login(credenciais) {
    const { data, status } = await this.post('/token/', credenciais);
    
    try {

      if (status === 200) {
      } else {
        console.error('Resposta inesperada:', data);

      }

    } catch (error) {
      console.error('Erro ao obter o token:', error);;
      throw error;
    }

    return { data, status };
  }

  async ultimosDezChamados(dados){

      const {data, status} = await this.get('/api/ocorrencias/municipio/CAMPINA GRANDE/ultimas10/', dados);

      try {
          console.log(data)
      } catch (error) {
          console.log(error)
      }

      return {data, status}
  }

  
}
